@import '../../../base/assets/scss/_base/config';

.m-bc{
  font-size: 13px;
  font-weight: 600;
  font-family: var(--font-family-heading);
  text-align: left;
  color: $backwater;

  &-content {
    padding: 0px 15px;
    &--noPad,
    &.cell {
      padding: 0;
    }
  }

  &__link{
    color: $backwater;
    text-decoration: none;
    &:hover{
      color: $blue;
    }
  }
}
